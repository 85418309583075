
:deep(.el-scrollbar__wrap) {
  overflow-x: hidden;
}
.course-box {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .operate-box {
    margin-bottom: 20px;
  }
}
.data-box {
  border: 1px solid #DADCE7;
  padding: 40px;
}
